import Image from 'next/image';
import Link from 'next/link';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div className="row">
            <div className="col-12 col-md-6 footer-app-icons">
              <div className="footer-app-icon me-3">
                <Image src={'/google-playstore.svg'} layout="fill" alt="Google Playstore" />
              </div>
              <div className="footer-app-icon">
                <Image src={'/ios-appstore.svg'} layout="fill" alt="iOS App store" />
              </div>
              <div className="quicklinks mt-3">
                <ul>
                  <li>Features</li>
                  <li>
                    <button className='btn p-0'
                      onClick={() => {
                        $crisp.push(['do', 'chat:open']);
                      }}
                    >
                      Help Center
                    </button>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link href="/refunds-and-cancellation">Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 footer-social-icons text-end">
              <div className="footer-social-icon me-3">
                <Link href={'https://www.facebook.com/1linksapp/'} passHref>
                  <a target={'_blank'}>
                    <Image src={'/home/facebook.svg'} width={30} height={30} layout="fixed" alt="Facebook" />
                  </a>
                </Link>
              </div>
              <div className="footer-social-icon me-3">
                <Link href={'https://www.instagram.com/1linksapp/'} passHref>
                  <a target={'_blank'}>
                    <Image src={'/home/instagram.svg'} width={30} height={30} layout="fixed" alt="Instagram" />
                  </a>
                </Link>
              </div>
              <div className="footer-social-icon me-3">
                <Link href={'https://twitter.com/1linksapp'} passHref>
                  <a target={'_blank'}>
                    <Image src={'/home/twitter.svg'} width={30} height={30} layout="fixed" alt="Twitter" />
                  </a>
                </Link>
              </div>
              <div className="footer-social-icon me-3">
                <Link href={'https://wa.me/919324034241'} passHref>
                  <a target={'_blank'}>
                    <Image src={'/home/whatsapp.svg'} width={30} height={30} layout="fixed" alt="Whatsapp" />
                  </a>
                </Link>
              </div>
              <div className="footer-social-icon">
                <Link href={'mailto:connect@1links.app'} passHref>
                  <a target={'_blank'}>
                    <Image src={'/home/email.svg'} width={30} height={30} layout="fixed" alt="Email" />
                  </a>
                </Link>
              </div>
            </div>
          </div>

          <div className="madein">
            Made in{' '}
            <span className="align-middle">
              <Image src="/home/heart.svg" width={15} height={15} alt="love" />
            </span>{' '}
            with India
          </div>
        </div>
      </div>
      <div className="text-center">Version : 1.21</div>
    </footer>
  );
}
